import Permissions from "src/lib/Permissions";

const routes = [
  {
    path: '/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {meta: {requiresAuth: false}, path: '/login', name: 'login', component: () => import('pages/auth/Login.vue')},
      {meta: {requiresAuth: false}, path: '/sso-authorization', name: 'sso-authorization', component: () => import('pages/auth/SsoAuthorization.vue')},
      {meta: {requiresAuth: false}, path: '/forgot-password', name: 'forgot-password', component: () => import('pages/auth/ForgotPassword.vue')},
      {meta: {requiresAuth: false}, path: '/password-reset', name: 'password-reset', component: () => import('pages/auth/PasswordReset.vue')},
      {meta: {requiresAuth: false}, path: '/privacy-policy', name: 'privacy-policy', component: () => import('pages/auth/PrivacyPolicy.vue')},
      {meta: {requiresAuth: false}, path: '/terms-of-use', name: 'terms-of-use', component: () => import('pages/auth/TermsOfUse.vue')},

      {meta: {requiresAuth: false}, path: '/surveys/:token', name: 'surveys', component: () => import('pages/forms_data/forms/PublicSurvey.vue')},


    ]
  },
  {
    path: '/app',
    component: () => import('layouts/MainLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {meta: {permissions: ''}, name: 'app', path: '', component: () => import('pages/dashboard/Dashboard.vue' )},

      {meta: {}, path: 'user-profile/:id', component: () => import('pages/account/UserProfile')},

      {meta: {}, path: 'notifications', component: () => import('pages/notifications/tables/NotificationsTable.vue')},

      // Crm reports
      {
        meta: {permissions: Permissions.crm_reports.average_response_time},
        path: 'crm-reports/average_response_time',
        component: () => import('pages/crm_reports/average_response_time/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.average_time_to_resolution},
        path: 'crm-reports/average_time_to_resolution',
        component: () => import('pages/crm_reports/average_time_to_resolution/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.calls_resulting_in_tickets},
        path: 'crm-reports/calls_resulting_in_tickets',
        component: () => import('pages/crm_reports/calls_resulting_in_tickets/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.sla_penalties},
        path: 'crm-reports/sla_penalties',
        component: () => import('pages/crm_reports/sla_penalties/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.tickets_opened_to_tickets_closed},
        path: 'crm-reports/tickets_opened_to_tickets_closed',
        component: () => import('pages/crm_reports/tickets_opened_to_tickets_closed/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.tickets_resulting_on_site_support},
        path: 'crm-reports/tickets_resulting_on_site_support',
        component: () => import('pages/crm_reports/tickets_resulting_on_site_support/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.tickets_service_category},
        path: 'crm-reports/tickets_service_category',
        component: () => import('pages/crm_reports/tickets_service_category/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.tickets_open_for_days},
        path: 'crm-reports/tickets_open_for_days',
        component: () => import('pages/crm_reports/tickets_open_for_days/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.opened_vs_closed_tickets},
        path: 'crm-reports/opened_vs_closed_tickets',
        component: () => import('../pages/crm_reports/opened_vs_closed_tickets/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.crm_reports.ticket_count_by_customer},
        path: 'crm-reports/ticket_count_by_customer',
        component: () => import('../pages/crm_reports/ticket_count_by_customer/ReportView.vue')
      },


      // Dwh reports

      {
        meta: {permissions: Permissions.dwh_reports.call_volume_per_range_of_call_taker_answer_time},
        path: 'dwh-reports/call_volume_per_range_of_call_taker_answer_time',
        component: () => import('pages/dwh_reports/call_volume_per_range_of_call_taker_answer_time/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.call_summary},
        path: 'dwh-reports/call_summary',
        component: () => import('pages/dwh_reports/call_summary/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.call_per_hours},
        path: 'dwh-reports/call_per_hour',
        component: () => import('pages/dwh_reports/call_by_hour/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.call_summary_detailed},
        path: 'dwh-reports/call_summary_detailed',
        component: () => import('pages/dwh_reports/call_summary_detailed/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.average_call_duration},
        path: 'dwh-reports/average_call_duration',
        component: () => import('pages/dwh_reports/average_call_duration/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.calls_by_agent},
        path: 'dwh-reports/calls_by_agent',
        component: () => import('pages/dwh_reports/calls_by_agent/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.agent_ring_time},
        path: 'dwh-reports/agent_ring_time',
        component: () => import('pages/dwh_reports/agent_ring_time/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.psap_ring_time},
        path: 'dwh-reports/psap_ring_time',
        component: () => import('pages/dwh_reports/psap_ring_time/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.agent_call_summary},
        path: 'dwh-reports/agent_call_summary',
        component: () => import('pages/dwh_reports/agent_call_summary/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.daily_call_taker_call_volume_per_hour_of_they_day},
        path: 'dwh-reports/daily_call_taker_call_volume_per_hour_of_they_day',
        component: () => import('pages/dwh_reports/daily_call_taker_call_volume_per_hour_of_they_day/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.call_log},
        path: 'dwh-reports/call_log',
        component: () => import('pages/dwh_reports/call_log/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.call_details},
        path: 'dwh-reports/call_details',
        component: () => import('pages/dwh_reports/call_details/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.psap_answer_time},
        path: 'dwh-reports/psap_answer_time',
        component: () => import('pages/dwh_reports/psap_answer_time/ReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.transfer_report},
        path: 'dwh-reports/transfer_report_details',
        component: () => import('pages/dwh_reports/transfer_report/DetailsReportView.vue')
      },
      {
        meta: {permissions: Permissions.dwh_reports.transfer_report},
        path: 'dwh-reports/transfer_report_count',
        component: () => import('pages/dwh_reports/transfer_report/CountReportView.vue')
      },
      // Settings start
      {meta: {permissions: Permissions.users.browse}, path: 'settings/users', component: () => import('pages/settings/users/view/UsersList.vue')},
      {meta: {permissions: Permissions.users.browse}, path: 'settings/users/:id', component: () => import('pages/settings/users/view/UsersDetail.vue')},

      {meta: {permissions: Permissions.buttons.browse}, path: 'settings/buttons', component: () => import('pages/settings/buttons/view/ButtonsList.vue')},
      {meta: {permissions: Permissions.buttons.browse}, path: 'settings/buttons/:id', component: () => import('pages/settings/buttons/view/ButtonsDetail.vue')},

      {meta: {permissions: Permissions.roles.browse}, path: 'settings/roles', component: () => import('pages/settings/roles/view/RolesList.vue')},
      {meta: {permissions: Permissions.roles.browse}, path: 'settings/roles/:id', component: () => import('pages/settings/roles/view/RolesDetail.vue')},

      {meta: {permissions: Permissions.forms_data.browse}, path: 'forms/:id', component: () => import('pages/forms_data/view/List.vue')},
      {meta: {permissions: Permissions.forms_data.browse}, path: 'forms-data/:id', component: () => import('pages/forms_data/view/Detail.vue')},

      {meta: {permissions: Permissions.crm_reports.monthly_ticket_report}, path: 'crm-reports/monthly-ticket-reports', component: () => import('pages/crm_reports/monthly_ticket_report/view/List.vue')},
      {meta: {permissions: Permissions.crm_reports.monthly_ticket_report}, path: 'crm-reports/monthly-ticket-reports/:id', component: () => import('pages/crm_reports/monthly_ticket_report/view/Detail.vue')},


      {meta: {permissions: Permissions.forms.browse}, path: 'settings/forms', component: () => import('pages/settings/forms/view/FormList.vue')},


      // Settings end


      {meta: {permissions: Permissions.general.settings_access}, path: 'settings', component: () => import('pages/settings/Settings.vue')},


    ]
  },
  {
    path: '/app/account',
    component: () => import('layouts/MainLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {path: '', component: () => import('pages/account/AccountPage.vue')},
    ]
  }
]


// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}


export default routes
